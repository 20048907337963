import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
try {
  // const socket = new WebSocket('wss://www.topsocial.com.cn/wss');
  const socket = new WebSocket('wss://www.topsocial.com.cn/wss');
  let reconnectInterval = 5000; // 初始重连间隔时间（毫秒）
  let maxReconnectInterval = 60000; // 最大重连间隔时间（毫秒）
  let reconnectAttempts = 0; // 重连尝试次数

  function reconnect() {
    if (reconnectAttempts > 0) {
      // 增加重连间隔时间
      reconnectInterval = Math.min(reconnectInterval * 2, maxReconnectInterval);
    }
    setTimeout(() => {
      const newSocket = new WebSocket('wss://www.topsocial.com.cn/wss');
      newSocket.onopen = function () {
        console.log('已成功重新连接');
        socket = newSocket;
        reconnectAttempts = 0;
        reconnectInterval = 5000;
      };
      newSocket.onclose = function () {
        console.log('重新连接失败，请重试...');
        reconnectAttempts++;
        reconnect();
      };
    }, reconnectInterval);
  }
  socket.onclose = function () {
    console.log('连接已关闭，正在尝试重新连接...');
    reconnect();
  };

  // 处理服务器发送的消息
  socket.onmessage = function (event) {
    if (event.data === 'ping') {
      // 收到服务器的心跳消息，发送响应
      socket.send('pong');
    } else {
      if (isJSON(event.data)) {
        const data = JSON.parse(event.data);
        const pageId = JSON.parse(localStorage.getItem('Authorization')); // 页面标识
        if (data.target == pageId) {
          addMessage();
        }
      }
    }
  };
  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  function addMessage() {
    // 创建弹框元素
    const modal = document.createElement('div');
    modal.className = 'modal-mask';
    modal.innerHTML = `
        <div class="modal">
            <div>当前设备登录数已达上限，如需多设备登录可升级会员</div>
            <button class="close-btn">确定</button>
        </div>
    `;
    // 添加到页面
    document.body.appendChild(modal);

    // 绑定关闭事件
    modal.querySelector('.close-btn').addEventListener('click', () => {
      document.body.removeChild(modal);
      location.reload();
    });
  }
} catch (error) {
  console.error(error.message);
}
export default {
  setup(__props) {
    //百度统计
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?d8d4a114a4a896ace6775fa589f87f83";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
    // //外链图片显示
    // import '@/utils/referrer.js'

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};